import React, { ReactElement } from 'react'
import { PrismicText, PrismicRichText } from '@prismicio/react'
import * as prismicHelpers from '@prismicio/helpers'
import htmlSerializer from '../../utils/htmlSerializer'

type TProps = {
  data: any[] | string
  defaultText?: string | ReactElement
  asText?: boolean
}

export const isPrismicText = (field) => {
  if (typeof field === 'string') {
    return field && field.length > 0
  }

  return field && prismicHelpers.asText(field)?.length > 0
}

export const prismicText = (field, defaultText = '') => {
  if (typeof field === 'string') {
    return isPrismicText(field) ? field : defaultText
  }

  return isPrismicText(field) ? prismicHelpers.asText(field) : defaultText
}

const CMSText = ({ data, defaultText = '', asText = false }: TProps) => {
  if (typeof data === 'string' && (data as string).length > 0)
    return <>{data}</>
  else if (isPrismicText(data)) {
    const c = asText ? (
      <PrismicText field={data} />
    ) : (
      <PrismicRichText field={data} components={htmlSerializer} />
    )

    return c
  }

  return <>{defaultText}</>
}

export default CMSText
